<template>
  <div id="homebox">
    <div class="flexspb tophead">
      <img src="../../../assets/logo.png" alt="" />
      <div class="logintext">
        <span v-if="type == '注册'">欢迎注册</span>
        <span v-if="type == '登录'">欢迎登录</span>
        <span v-if="type == '重置密码'">重置密码</span>
      </div>
      <div class="havecode" v-if="type == '注册'">已有账号?</div>
      <div class="backhome" @click="login(type)">
        <span v-if="type == '注册'">返回登录页</span>
        <span v-if="type == '重置密码'">返回登录页</span>
        <span v-if="type == '登录'">返回首页</span>
        <i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loginhead", //头部样式
  props: {
    // 当前的页码(1级菜单)
    type: {
      typeof: [String],
    },
  },
  data() {
    return {
      path: this.$base.baseUrl, //
    };
  },
  methods: {
    //   点击到首页
    login(type) {
      var path;
      if (type == "登录") {
        path = "/home";
      } else if (type == "注册") {
        path = "/login";
      } else if(type == "重置密码") {
        path = "/login";
      }
      this.until.jump.call(this, path, "push");
    },
  },
};
</script>
<style lang="less"  scoped>
@media screen and (min-width: 1000px) {
  // 首页
  #homebox {
    background-color: #f9f9f9;
    .tophead {
      width: 75.625rem;
      height: 6.875rem;
      img {
        width: 3.125rem;
        height: 3.125rem;
      }
      .logintext {
        flex: 1;
        font-size: 1.5rem;
        color: #333;
        margin-left: 1.25rem;
      }
      .havecode {
        font-size: 0.875rem;
        margin-right: 0.625rem;
      }
      .backhome {
        color: #ff0000;
        font-size: 0.875rem;
        cursor: pointer;
      }
      .backhome:hover {
        color: #fda9a9;
      }
      i {
        margin-top: 0.25rem;
        margin-left: 0.125rem;
      }
    }
  }
}

@media screen and (max-width: 999px) {
  // 首页
  #homebox {
    background-color: #f9f9f9;
    .tophead {
      width: 100%;
      height: 6.875rem;
      padding: 0 0.9375rem;
      img {
        width: 3.125rem;
        height: 3.125rem;
      }
      .logintext {
        flex: 1;
        font-size: 1.5rem;
        color: #333;
        margin-left: 1.25rem;
      }
      .havecode {
        font-size: 0.875rem;
        margin-right: 0.625rem;
      }
      .backhome {
        color: #ff0000;
        font-size: 0.875rem;
        cursor: pointer;
      }
      i {
        margin-top: 0.25rem;
        margin-left: 0.125rem;
      }
    }
  }
}
</style>
