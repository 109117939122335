<template>
  <div class="boxpa">
    <div class="topres">
      <loginhead type="注册" />
    </div>
    <div class="flexcent frombox">
      <el-form :model="form" :rules="formRules" ref="regForm">
        <el-form-item label="用户名" prop="username">
           <el-input type="text" v-model="form.username" placeholder="请输入用户名" maxlength="80" rows="1"/>
        </el-form-item> 
        <el-form-item label="密码" prop="password" >
           <el-input type="password" v-model="form.password" placeholder="请输入密码,长度在8到30个字符,包含字母数字和特殊字符" maxlength="80" rows="1"/>
        </el-form-item> 
        <el-form-item label="再次输入密码" prop="password2">
           <el-input type="password" v-model="form.password2" placeholder="请再次输入密码" maxlength="80" rows="1"/>
        </el-form-item> 
        <el-form-item label="单位" prop="corpName">
           <el-input type="text" v-model="form.corpName" placeholder="请输入单位" maxlength="80" rows="1"/>
        </el-form-item> 
        <el-form-item label="单位代码" prop="corpCode">
           <el-input type="text" v-model="form.corpCode" placeholder="请输入单位代码" maxlength="80" rows="1"/>
        </el-form-item> 
				<el-form-item label="电子邮箱" prop="email">
           <el-input type="text" @blur="onEmailChanged" v-model="form.email" placeholder="请输入电子邮箱" maxlength="80" rows="1"/>
        </el-form-item> 
        <el-form-item label="邮箱验证码" prop="emailCode" disabled="true">
           <el-input type="text" v-model="form.emailCode" placeholder="请输邮箱验证码" maxlength="40" rows="1" 
            :disabled="emailCodeStatus" style="width: 200px"/>
            <el-button :disabled="emailStatus" type="primary"  @click="showVerify">{{sendEmailTitle}}</el-button>
        </el-form-item> 
        <el-form-item label="联系电话" prop="phone">
           <el-input type="text" v-model="form.phone" placeholder="请输入联系电话" maxlength="80" rows="1"/>
        </el-form-item> 
		
        
        <!-- <div v-for="(item, index) in inputlist" :key="index">
          <el-form-item :label="item.name" :prop="item.prop">
            <el-input
              :type="item.type"
              v-model="form[item.prop]"
              :placeholder="item.placeholder"
              :maxlength="item.max"
              :rows="4"
            />
          </el-form-item>
        </div> -->
      </el-form>
    </div>
    <div class="flexcent">
      <div class="flexcent regbtn" @click="register">{{submitting ? '正在注册': '注册'}}</div>
    </div>
    <div >
       <Verify
          @success="verifySuccess"
          :mode="'pop'"
          :captchaType="'blockPuzzle'"
          :imgSize="{ width: '330px', height: '155px' }"
          ref="verify"
        />
    </div>
  </div>
</template>

<script>
import { checkPassword } from "../../config/replace";
import { encryption } from "@/components/verifition/utils/ase";
import loginhead from "@/views/login/comp/loginhead";
import Verify from "@/components/verifition/Verify";
export default {
 components: {
    loginhead, //登录
    Verify,
  },
  data() {
    var checkName = (rule, value, callback) => {
      this.$api.checkName(value).then((res) => {
        console.log(" check name ", res)
        if (res.data != null && res.data.exist ) {
          callback(new Error("用户名已存在"));
        } else {
            callback();
        }
      })
    }
    // var checkMobile = (rule, value, callback) => {
    //   this.$api.checkMobile(value).then((res) => {
    //     console.log(" check mobile ", res)
    //     if (res.data != null && res.data.exist ) {
    //       callback(new Error("手机号已存在"));
    //     } else {
    //         callback();
    //     }
    //   })
    // }
    var checkEmailCode = (rule, value, callback) => {
      console.log("email code checker ")
      const data = {
        username: this.form.username,
        email:this.form.email,
        emailCode:this.form.emailCode,
      }
      console.log(" === checkemailcode ", data)
      this.$api.checkEmailCode(data).then((res) => {
        console.log(" check email ", res)
        if (res.data != null && !res.data.exist ) {
          callback(new Error("验证码错误，请重新输入"));
        } else {
          callback();
        }
      }).catch((err) => {
          console.info(" login error ", err)
          callback(new Error("系统错误，请稍后重试"));
      })
    }
    var checkEmail = (rule, value, callback) => {
      console.log(" verify email")
      this.$api.checkEmail(value).then((res) => {
        console.log(" check email ", res)
        if (res.data != null && res.data.exist ) {
          this.emailStatus = true;
          callback(new Error("电子邮箱已存在"));
        } else {
            this.emailStatus = false
            callback();
        }
      }).catch((e) => {
          console.error(" login error ", e)
          callback(new Error("系统错误，请稍后重试"));
      })
    }
   
     var checkPhone = (rule, value, callback) => {
      console.log("  phone ", value)
      if(!value) {
        console.log(" phone null, no check, direct call,")
        callback()
        return 
      }
      this.$api.checkPhone(value).then((res) => {
        console.log(" check phone ", res)
        callback()
        if (res.data != null && res.data.exist ) {
          callback(new Error("联系电话已存在"));
        } else {
          callback();
        }
      })
    }
    var validatePass2 = (rule, value, callback) => {
       console.log(" validatePass2  ", value)
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
    }
    // var validPhone = (rule, value, callback) => {
    //     if (value === '') {
    //       callback(new Error('请再次输入密码'));
    //     } else if (value !== this.form.password) {
    //       callback(new Error('两次输入密码不一致!'));
    //     } else {
    //       callback();
    //     }
    // }
    return {
      submitting: false,
      emailStatus: true,
      emailCodeStatus: true,
      sendEmailTitle: "发送验证码",
      form: {
        username: "", //用户名
        password: "", //密码
        password2: "",
        email:"",
        emailCode: "",
        corpName: "", //单位
        corpCode: "", //单位代码
        phone: "", //电话
        status: 1,
        // $lockFlag: "锁定",
        lockFlag: "0", // 默认生效
        tenantId: '2', //门户用户
      },
      formRules: {
        username: [
          { required: true, message: "请输入用户名!", trigger: "blur" },
          {
            min: 3,
            max: 30,
            message: "长度在 3 到 20 个字符!",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码!", trigger: "blur" },
          {
            min: 8,
            max: 30,
            message: "长度在 8 到 30 个字符,包含字母、数字和特殊字符",
            trigger: "blur",
          },
          { validator: checkPassword, trigger: "blur" },
        ],
        password2: [
          { required: true, message: "请输入重复密码!", trigger: "blur" },
          {
            min: 8,
            max: 30,
            message: "两次输入密码不一致",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入电子邮件!", trigger: "blur" },
          {
            min: 6,
            max: 50, 
            message: "邮箱格式xxx@xxx.xxx!",
            trigger: "blur",
            pattern: "^[A-Za-z\\d]+([-_.][A-Za-z\\d]+)*@([A-Za-z\\d]+[-.])+[A-Za-z\\d]{2,5}$",
          },
          { validator: checkEmail, trigger: "blur" },
        ],
         emailCode: [
          { required: true, message: "请输入验证码!", trigger: "blur" },
          {
            min: 4,
            max: 10, 
            message: "请输入验证码",
            trigger: "blur",
          },
          { validator: checkEmailCode, trigger: "blur" },
        ],
        // mobile: [
        //   { required: true, message: "请输入手机号!", trigger: "blur" },
        //   {
        //     min: 11,
        //     max: 11,
        //     message: "请输入11位手机号",
        //     trigger: "blur",
        //     pattern: "^(1[0-9]{10})$",
        //   },
        //   { validator: checkMobile, trigger: "blur" },
        // ],
        phone: [
          { required: false, message: "请输入联系电话!", trigger: "blur" },
          {
            min: 11,
            max: 17,
            //message: "请输入区号-座机号",
            message: "请输入联系电话",
            trigger: "blur",
            // pattern: "^(1)$",
            // pattern: "^(1[0-9]{10})$",
           //  pattern: "^0[0-9]{2,3}-[0-9]{7,8}(-[0-9]{3,5}){0,1}$",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
      },
      //   输入的数组
      inputlist: [
        {
          name: "用户名",
          prop: "username",
          placeholder: "请输入用户名",
          type: "text",
          max: 80,
        },
        {
          name: "密码",
          prop: "password",
          placeholder: "请输入密码",
          type: "password",
          max: 80,
        },
        {
          name: "再次输入密码",
          prop: "password2",
          placeholder: "请再次输入密码",
          type: "password",
          max: 80,
        },
        {
          name: "单位",
          prop: "corpName",
          placeholder: "请输入单位",
          type: "text",
          max: 30,
        },
        {
          name: "单位代码",
          prop: "corpCode",
          placeholder: "请输入单位代码",
          type: "text",
          max: 30,
        },
        {
          name: "邮箱",
          prop: "email",
          placeholder: "请输入邮箱",
          type: "text",
          max: 30,
        },
        // {
        //   name: "手机号",
        //   prop: "mobile",
        //   placeholder: "请输入联系电话",
        //   type: "text",
        //   max: 11,        
        // },
        {
          name: "联系电话",
          prop: "phone",
          placeholder: "请输入联系电话",
          type: "text",
          max: 17,     
        },
        // {
        //   name: "角色",
        //   list: [],
        // },
      ],
    };
  },
 
  mounted() {
    // this.getlist();
  },
  methods: {
    onEmailChanged(){
      console.log("  email changed ")
      this.emailStatus = true
      // this.emailCodeStatus = true
    },
     // 登陆成功
    showVerify(){
      this.$refs.verify.show()
    },
    verifySuccess(res) {
     console.log(" === verify ", res);
     this.sendEmailTitle = "正在发送邮件";
    this.emailStatus = true
    let me = this
     this.$api.sendEmailCode(this.form).then(() => {
        this.sendEmailTitle = "已发送邮件";
        this.emailStatus = true
        this.emailCodeStatus = false
        setTimeout(function(){
            console.log(" timeout ")
            me.sendEmailTitle = "重新发送"
            me.emailStatus = false
          },
          3000)
        })
        .catch((e) => {
          console.error(" login error ", e)
          this.sendEmailTitle = "请重新发送";
          this.emailStatus = false
        });
      // this.$api
      //   .login(data, params)
      //   .then((res) => {
      //      this.submitting = false;
      //      console.log(" get login info ", res)
      //     this.until.cacheLogin(res);
      //     this.until.cacheSync("set", "aitp-userInfo", res.user_info);
      //     let url = this.redirect ? this.redirect : '/home'
      //     this.until.jump.call(this, url, "push");
      //   })
      //   .catch((e) => {
      //     console.error(" login error ", e)
      //      this.submitting = false;
      //     return this.$message.warning("用户名不存在或者密码错误");
      //   });
    },
    // 获取角色
    // getlist() {
    //   this.$api.getRoleList().then((res) => {
    //     this.inputlist[6].list = res.data;
    //   });
    // },
    //   点击到首页
    login() {
      this.until.jump.call(this, "/login", "push");
    },
    // 点击进行注册
    register() {
        this.$refs["regForm"].validate((valid) => {
          console.log(" valid ", valid)
          if (valid) {
            console.log("  do reg")
            this.doReg()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },

    doReg(){
       // var list = [];
      // for (let a = 0; a < this.form.role.length; a++) {
      //   for (let j = 0; j < this.inputlist[5].list.length; j++) {
      //     if (this.form.role[a] == this.inputlist[5].list[j].roleName) {
      //       list.push(this.inputlist[5].list[j].roleId);
      //     }
      //   }
      // }
      // this.form.role = list;
     
      // for (let a = 0; a < this.inputlist.length; a++) {
      //   const item = this.inputlist[a];
      //   if (this.form[item.prop] == "") {
      //     return this.$message.warning(item.name + "不能为空");
      //   }
      // }
      // if (this.form.role.length == 0) {
      //   return this.$message.warning("请选择角色");
      // }
      // debugger
      this.submitting = true
      const user = encryption({
        data: this.form,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      console.log("  user.password" , user)
      // const data = {
      //   username: user.username,
      //   password: user.password,
      //   email: this.form.email,
      //   emailCode: this.form.code,
      //   role: this.form.role,
      //   phone: this.form.phone,
      //   status: this.form.status,
      //   // $lockFlag: "锁定",
      //   lockFlag: this.form.lockFlag,
      //   tenantId: this.form.tenantId, //门户用户
      // }
      this.$api.register(this.form).then((res) => {
        this.submitting=false
        if (res.code == 0) {
          this.$message.success("注册成功，请登录");
          let path = "/login";
          this.until.jump.call(this, path, "push");
          for (let a = 0; a < this.inputlist.length; a++) {
            const item = this.inputlist[a];
            this.form[item.prop] = "";
          }
        }
      }).catch((err)=>{
        this.submitting=false
        // console.error(err)
        console.log(err);
      });
    }
  },
};
</script>

<style lang="less" >
.el-message {
  padding: 0.9375rem 1.25rem;
  font-size: 0.875rem;
}
</style>
<style lang="less"  scoped>
// @media screen and (min-width: 1000px) {
.boxpa {
  // background-color: #f9f9f9;
  // width: 800px ;
  height: 100vh;
  border: #3a8ee6 1px;
  // 首页
  .topres {
    box-shadow: 0 0 0.625rem #d1d1d1;
  }
  .frombox {
    width: 100%;
    /deep/ .el-form {
      width: 34.375rem;
      margin-top: 5rem;
      .el-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 1.125rem;
        .el-form-item__label {
          width: 6.875rem;
          box-sizing: border-box;
          text-align: right;
          padding-right: 0.75rem;
          font-size: 0.875rem;
          color: #606266;
        }
        .el-form-item__content {
          flex: 1;
          display: flex;
          align-items:center;
          .el-checkbox__inner {
            width: 1rem;
            height: 1rem;
            border: 0.0625rem solid #dcdfe6;
            border-radius: 0.125rem;
          }
          .el-checkbox__label {
            margin-left: 0.1875rem;
            font-size: 0.75rem;
          }
          .el-checkbox__inner::after {
            border: 0.125rem solid #fff;
            height: 0.5rem;
            width: 0.3rem;
            border-left: 0;
            left: 0.1875rem;
            border-top: 0;
          }
          button {
            padding: 0 1rem 0 1rem;
            margin-left: 1rem;
            height: 2rem;
            width: 50%;
          }
          input {
            height: 2rem;
            padding: 0 1.25rem;
            border-radius: 0.3125rem;
            box-sizing: border-box;
            font-size: 0.875rem;
            .emcode{
              width: 10rem;
            }
          }
          textarea {
            width: 27.5rem;
            padding: 0.625rem 1.25rem;
            border-radius: 0.3125rem;
            box-sizing: border-box;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
  .regbtn {
    padding: 0 1rem 0 1rem;
    margin: 1rem;
    height: 2rem;
    
    color: #fff;
    background-color: #409eff;
    border-radius: 0.3125rem;
    font-size: 0.75rem;
  }
  .regbtn:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .regbtn:active {
    background-color: #3a8ee6;
  }
}
// }

// @media screen and (max-width: 999px) {
// .boxpa {
//   background-color: #f9f9f9;
//   height: 100vh;
//   // 首页
//   .topres {
//     box-shadow: 0 0 0.625rem #d1d1d1;
//   }
//   .frombox {
//     width: 100%;
//     /deep/ .el-form {
//       width: 100%;
//       padding:  0 .9375rem;
//       box-sizing: border-box;
//       margin-top: 5rem;
//       .el-form-item {
//         display: flex;
//         align-items: center;
//         margin-bottom: 1.125rem;
//         .el-form-item__label {
//           width: 6.875rem;
//           box-sizing: border-box;
//           text-align: right;
//           padding-right: 0.75rem;
//           font-size: 0.875rem;
//           color: #606266;
//         }
//         .el-form-item__content {
//           flex: 1;
//           .el-checkbox__inner {
//             width: 1rem;
//             height: 1rem;
//             border: 0.0625rem solid #dcdfe6;
//             border-radius: 0.125rem;
//           }
//           .el-checkbox__label {
//             margin-left: 0.1875rem;
//             font-size: 0.75rem;
//           }
//           .el-checkbox__inner::after {
//             border: 0.125rem solid #fff;
//             height: 0.5rem;
//             width: 0.3rem;
//             border-left: 0;
//             left: 0.1875rem;
//             border-top: 0;
//           }
//           input {
//             height: 2rem;
//             width: 14rem;
//             padding: 0 1.25rem;
//             border-radius: 0.3125rem;
//             box-sizing: border-box;
//             font-size: 0.875rem;
//           }
//           textarea {
//             width: 14rem;
//             padding: 0.625rem 1.25rem;
//             border-radius: 0.3125rem;
//             box-sizing: border-box;
//             font-size: 0.875rem;
//           }
//         }
//       }
//     }
//   }
//   .regbtn {
//     width: 3.25rem;
//     height: 2.1875rem;
//     color: #fff;
//     background-color: #409eff;
//     border-radius: 0.3125rem;
//     font-size: 0.75rem;
//   }
//   .regbtn:hover {
//     cursor: pointer;
//     opacity: 0.7;
//   }
//   .regbtn:active {
//     background-color: #3a8ee6;
//   }
// }}
</style>
