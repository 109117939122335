<template>
  <div class="logbox">
    <el-form
      ref="loginForm"
      :rules="loginRules"
      :model="loginForm"
      status-icon
      label-width="0"
    >
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          size="small"
          auto-complete="off"
          placeholder="请输入用户名"
          @keyup.enter.native="handleLogin"
        >
          <i slot="prepend" class="el-icon-user" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          :type="passwordType"
          v-model="loginForm.password"
          size="small"
          auto-complete="off"
          placeholder="请输入密码"
          @keyup.enter.native="handleLogin"
        >
          <!-- <i slot="suffix" class="el-icon-view el-input__icon" @click="showPassword" /> -->
          <i slot="prepend" class="el-icon-lock"></i>
        </el-input>
      </el-form-item>
   
      <el-form-item>
        <el-button
          type="primary"
          size="small"
          class="login-submit"
          @click.native.prevent="handleLogin"
          :disabled="submitting"
          >{{ submitting? "正在登陆": "登录"}}</el-button
        >
      </el-form-item>

      <el-form-item>
        <span class="reginfo" @click="toresetpwd">忘记密码</span>
        <span class="reginfo" @click="toresig">立即注册</span>
      </el-form-item>
    
     
<!-- 
      <el-form-item>
       <div class="forget">忘记密码</div>
      </el-form-item> -->

       <el-form-item prop="code">
        <Verify
          @success="verifySuccess"
          :mode="'pop'"
          :captchaType="'blockPuzzle'"
          :imgSize="{ width: '330px', height: '155px' }"
          ref="verify"
        />
      </el-form-item>
    </el-form>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { encryption } from "@/components/verifition/utils/ase";
import Verify from "@/components/verifition/Verify";
import qs from "qs";

export default {
  name: "loginbox",
  components: {
    Verify,
  },
  data() {
    return {
      submitting:false,
      redirect:'',
      // socialForm: {
      //   code: "",
      //   state: "",
      // },
      loginForm: {
        username: "",
        password: "",
        code: "",
        randomStr: "blockPuzzle",
      },
      checked: false,
      code: {
        src: undefined,
        len: 4,
      },
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码长度最少为6位", trigger: "blur" },
        ],
      },
      passwordType: "password",
    };
  },
  created(){
    this.redirect = this.$route.query.redirect
  },
  computed: {
    ...mapGetters(["tagWel"]),
  },
  methods: {
    toresetpwd(){
      this.until.jump.call(this, "/resetpwd", "push");  
    },
    toresig() {
      this.until.jump.call(this, "/register", "push");
    },
    showPassword() {
      this.passwordType == ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$refs.verify.show();
        }
      });
    },
    // 登陆成功
    verifySuccess(res) {
      this.loginForm.code = res.captchaVerification;
      const user = encryption({
        data: this.loginForm,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = qs.stringify({
        username: user.username,
        password: user.password,
      });
      let params = {
        randomStr: user.randomStr,
        code: user.code,
        grant_type: "password",
      };
      this.submitting = true;
      this.$api
        .login(data, params)
        .then((res) => {
           this.submitting = false;
           console.log(" get login info ", res)
          this.until.cacheLogin(res);
          this.until.cacheSync("set", "aitp-userInfo", res.user_info);
          let url = this.redirect ? this.redirect : '/home'
          this.until.jump.call(this, url, "push");
        })
        .catch((e) => {
          console.error(" login error ", e)
           this.submitting = false;
          return this.$message.warning("用户名不存在或者密码错误");
        });
    },
  },
};
</script>

<style >
.el-message {
  padding: 0.9375rem 1.25rem;
  font-size: 0.875rem;
}
</style>
<style scoped lang="less">
// @media screen and (min-width: 1000px) {
  // 盒子内容
  
  .logbox {
    width: 100%;
    height: 100%;
    .forget{
      font-size: .75rem;
      text-align: right;
      width: 100%;
      padding-right: .3125rem;
      color: #494949;
      cursor: pointer;
    }
    .forget:hover{
      color: #7689fd;
    }
    /deep/ .el-form {
      width: 100%;
      height: 100%;
      .el-form-item__content{
        display: flex;
        justify-content: center;
        .el-button{
          border-radius: .3125rem;
        width: 4.375rem;
        height: 2rem;
      }
      }
      
      .el-form-item{
        margin-bottom: 1.25rem;
        .el-input {
          height: 2.5rem;
          .el-input-group__prepend {
            width: 2.9375rem;
            padding: 0;
            margin: 0;
            text-align: center;
            border-top-left-radius: 0.3125rem;
            border-bottom-left-radius: 0.3125rem;
            i {
              font-size: 0.875rem;
              padding: 0;
              margin: 0;
            }
          }
          input {
            height: 2.5rem;
            border-top-right-radius: 0.3125rem;
            border-bottom-right-radius: 0.3125rem;
            padding-left: .625rem;
          }
        }
        .login-submit {
          width: 100%;
        }
        .reginfo {
          padding: 0px 50px 0px 50px ;
          font-size: 0.75rem;
          color: #4427e7;
          // font-weight: 400;
          cursor: pointer;
          align-content: center;
          width: 100%;
        }
        .reginfo:hover {
          color: #1a28ec;
          background-color: #e1e5f7;
        }
       
      }
      .el-form-item__error{
        margin: .3125rem;
      }
      .el-input__suffix{
        .el-input__icon{
          width: 1.5rem;
          i{
            font-size: .9375rem;
          }
        }
      }
    }
  }
// }

// @media screen and (max-width: 999px) {
//   // 盒子内容
//   .logbox {
//     width: 100%;
//     height: 100%;
//     .forget{
//       font-size: .75rem;
//       text-align: right;
//       width: 100%;
//       padding-right: .3125rem;
//       color: #494949;
//       cursor: pointer;
//     }
//     .forget:hover{
//       color: #7689fd;
//     }
//     /deep/ .el-form {
//       width: 100%;
//       height: 100%;
//       .el-form-item__content{
//         display: flex;
//         justify-content: center;
//         .el-button{
//           border-radius: .3125rem;
//         width: 4.375rem;
//         height: 2rem;
//       }
//       }
      
//       .el-form-item{
//         margin-bottom: 1.25rem;
//         .el-input {
//         height: 2.5rem;
//         .el-input-group__prepend {
//           width: 2.9375rem;
//           padding: 0;
//           margin: 0;
//           text-align: center;
//           border-top-left-radius: 0.3125rem;
//           border-bottom-left-radius: 0.3125rem;
//           i {
//             font-size: 0.875rem;
//             padding: 0;
//             margin: 0;
//           }
//         }
//         input {
//           height: 2.5rem;
//           border-top-right-radius: 0.3125rem;
//           border-bottom-right-radius: 0.3125rem;
//           padding-left: .625rem;
//         }
//       }
//       }
//       .el-form-item__error{
//         margin: .3125rem;
//       }
//       .el-input__suffix{
//         .el-input__icon{
//           width: 1.5rem;
//           i{
//             font-size: .9375rem;
//           }
//         }
//       }
//     }
//   }
// }
</style>