<template>
  <div class="boxpa">
    <div>
      <loginhead type="登录" />
    </div>
    <div class="backimg flexcent">
      <img src="@/assets/login.png" alt="" />
      <div class="cardbox">
        <div class="flexspb cardhead">
          <span>账号登录</span>
        </div>
        <div class="cardcent">
          <loginbox />
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import loginbox from "./comp/loginbox";
import loginhead from "./comp/loginhead";
export default {
  components: {
    loginhead,
    loginbox,
  },
  methods: {
    // toresig() {
    //   this.until.jump.call(this, "/register", "push");
    // },
  },
};
</script>


<style lang="less"  scoped>
// @media screen and (min-width: 1000px) {
  .boxpa {
    background-color: #f9f9f9;
    height: 100vh;
    .backimg {
      height: 31.25rem;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        opacity: 0.7;
      }
    }
    .cardbox {
      z-index: 1;
      box-sizing: border-box;
      width: 21.875rem;
      height: 18.125rem;
      background-color: #ffffff;
      border-radius: 0.3125rem;
      box-shadow: 0 0 0.125rem #ffffff;
      .cardhead {
        padding: 1.125rem 1.25rem;
        font-size: 1rem;
        font-weight: 550;
        border-bottom: 1px solid #ebeef5;
        color: #303133;
        .resger {
          font-size: 0.75rem;
          color: #409eff;
          font-weight: 400;
          cursor: pointer;
        }
        .resger:hover {
          color: #9bcdff;
        }
      }
      .cardcent {
        padding: 1.25rem;
        height: 11.25rem;
        width: 19.375rem;
      }
    }
  }
// }

// @media screen and (max-width: 999px) {
//   .boxpa {
//     background-color: #f9f9f9;
//     height: 100vh;
//     .backimg {
//       height: 31.25rem;
//       position: relative;
//       img {
//         width: 100%;
//         height: 100%;
//         position: absolute;
//         z-index: 0;
//         opacity: 0.7;
//       }
//     }
//     .cardbox {
//       z-index: 1;
//       box-sizing: border-box;
//       width: 21.875rem;
//       height: 17.5rem;
//       background-color: #ffffff;
//       border-radius: 0.3125rem;
//       box-shadow: 0 0 0.125rem #ffffff;
//       .cardhead {
//         padding: 1.125rem 1.25rem;
//         font-size: 1rem;
//         font-weight: 550;
//         border-bottom: 1px solid #ebeef5;
//         color: #303133;
//         .resger {
//           font-size: 0.75rem;
//           color: #409eff;
//           font-weight: 400;
//           cursor: pointer;
//         }
//         .resger:hover {
//           color: #9bcdff;
//         }
//       }
//       .cardcent {
//         padding: 1.25rem;
//         height: 11.25rem;
//         width: 19.375rem;
//       }
//     }
//   }
// }
</style>
